import useWishlist from "@/lib/hooks/useWishlist";
import React, { useEffect, useState, forwardRef, Ref } from "react";
import { useDebouncedCallback } from "use-debounce";

import { WishlistAddEditJson } from "@/types/Wishlist.type";

import HeartIcon from "@/components/icons/HeartIcon";
import HeartFilledIcon from "@/components/icons/HeartFilledIcon";
import useWishlistV2 from "@/lib/hooks/useWishlistV2";

type Props = {
	model: WishlistAddEditJson;
	handleErrors?: (errors: string[]) => void;
	handleSuccess?: () => void;
	label?: React.ReactNode;
	className?: string;
	classNameWrapper?: string;
	classNameHeartActive?: string;
};

const WishlistBtn = forwardRef(
	(
		{
			model,
			handleErrors,
			handleSuccess,
			label,
			className,
			classNameWrapper = "",
			classNameHeartActive,
		}: Props,
		ref: Ref<HTMLDivElement>
	) => {
		const { handleWishListActions, exists } = useWishlistV2(model);

		const [heartMode, setHeartMode] = useState<boolean>(exists);

		const handleOnClickBtn = useDebouncedCallback(() => {
			handleWishListActions(model).finally(() => {
				setHeartMode(!heartMode);
			});
		}, 200);

		useEffect(() => {
			setHeartMode(exists);
		}, [exists]);

		return (
			<div
				ref={ref}
				onClick={() => {
					handleOnClickBtn();
				}}
				className={"cursor-pointer " + classNameWrapper}>
				{heartMode ? (
					<HeartFilledIcon className={`${className} ${classNameHeartActive}`} />
				) : (
					<HeartIcon className={className} />
				)}
				{label}
			</div>
		);
	}
);

// function WishlistBtn({
//   model,
//   handleErrors,
//   handleSuccess,
//   label,
//   className,
//   classNameWrapper = "",
// }: Props) {

// }

export default WishlistBtn;
